<template>
  <v-btn @click="fetch">{{ id }}</v-btn>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'personengymnet',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  methods: {
    fetch () {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!) {
            StbM2021GymnetImportAthletes(id: $id)
          }
        `,
        variables: {
          id: this.id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
